export const calc_termins = [
  {
    label: "1 тиж",
    value: 0.25,
  },
  {
    label: "1 міс",
    value: 1,
  },
  {
    label: "2 міс",
    value: 2,
  },
  {
    label: "3 міс",
    value: 3,
  },
  {
    label: "4 міс",
    value: 4,
  },
  {
    label: "5 міс",
    value: 5,
  },
  {
    label: "6 міс",
    value: 6,
  },
  {
    label: "12+ міс",
    value: 12,
  },
];
